@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

body {
  background-image: url(./assets/assets/Woman.jpg)!important;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Lato', sans-serif;
  background-attachment: fixed;
}

.logo {
  width: 750px;
  height: auto;
}

.text-center {
  color: #ededeb;
}

.card {
  background-color: rgba(255, 255, 255, 0.67)!important;
}

.btn-primary {
  background-color: #8a764e!important;
  color: #ededeb!important;
  border: none!important;
  font-weight: 500!important;
}

.card {
  width: 75%!important;
}

p.description-text {
  font-size: 18px;
  letter-spacing: 2px;
}

@media (min-width: 576px) {
.container, .container-sm, .container-md, .container-lg {
    max-width: 95%!important;
  }
}

@media (max-width: 440px) {
  body {
      background-image: url(./assets/assets/Woman2.jpg)!important;
      background-color: black;
      min-height: 100vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-position-x: center;
      background-attachment: fixed;

  }
  .card{
    width: 100%!important;
    margin-bottom: 45px;
  }
  p.description-text {
    width: 75%;
  }
}
