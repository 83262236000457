@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{background-image:url(/static/media/Woman.638adddc.jpg) !important;height:100vh;background-repeat:no-repeat;background-size:cover;font-family:'Lato', sans-serif;background-attachment:fixed}.logo{width:750px;height:auto}.text-center{color:#ededeb}.card{background-color:rgba(255,255,255,0.67) !important}.btn-primary{background-color:#8a764e !important;color:#ededeb !important;border:none !important;font-weight:500 !important}.card{width:75% !important}p.description-text{font-size:18px;letter-spacing:2px}@media (min-width: 576px){.container,.container-sm,.container-md,.container-lg{max-width:95% !important}}@media (max-width: 440px){body{background-image:url(/static/media/Woman2.4c16d028.jpg) !important;background-color:black;min-height:100vh;background-repeat:no-repeat;background-size:cover;background-position-x:center;background-attachment:fixed}.card{width:100% !important;margin-bottom:45px}p.description-text{width:75%}}

